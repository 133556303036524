<template lang="">
  <div>
    <footer id="footer" class="footer accent-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-12 footer-about text-center d-flex flex-column align-items-center">
            <a href="index.html" class="col-lg-5 order-1 order-lg-2">
              <!-- Uncomment the line below if you also wish to use an image logo -->
              <img src="assets/img/hero-img.svg" class="img-fluid" style="width: 50%" />
            </a>
            <p class="mt-4"><strong>Reviving Ancient Healing Wisdom.</strong></p>
            <p class="mt-4">
              Helathel by Amila Rajakaruna Mudiyanse Private Limited – Your trusted source for authentic, ancient
              medicinal oils. Rooted in tradition, driven by wellness.
            </p>
            <div class="social-links d-flex justify-content-center mt-4">
              <a href=""><i class="bi bi-twitter-x"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>
          <strong class="px-1 sitename">Helathel</strong>
          <span>All Rights Reserved</span>
        </p>
        <div class="credits">Powered by <a href="https://hashloopit.com/">Hashloop IT Solutions</a></div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "LayoutFooter",
};
</script>
<style lang=""></style>
