<template>
  <div>
    <header id="header" class="header fixed-top">
      <!-- Top Bar -->
      <LayoutHeader></LayoutHeader>
      <!-- End Top Bar -->

      <!-- Branding -->
      <div class="branding d-flex align-items-center">
        <div class="container position-relative d-flex align-items-center justify-content-between">
          <router-link to="/" class="logo d-flex align-items-center">
            <img src="assets/img/logo/logo.png" style="width: 150px !important; height: 150px !important" alt="Logo" />
          </router-link>
          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <router-link to="/" class="active" @click="closeMobileNav">Home<br /></router-link>
              </li>
              <li>
                <router-link to="/product-page" class="active" @click="closeMobileNav">Product<br /></router-link>
              </li>
              <!-- Add more navigation items as needed -->
            </ul>

            <!-- Mobile nav toggle button -->
            <i class="mobile-nav-toggle d-xl-none bi" :class="mobileNavActive ? 'bi-x' : 'bi-list'"
              @click="toggleMobileNav"></i>
          </nav>
        </div>
      </div>
    </header>

    <main class="main">
      <div class="page-title">
        <div class="heading">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
                <h2>The Great Pinda</h2>
                <h5>ANCIENT MEDICINE OIL</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Product Details Section -->
      <div class="container mt-5">
        <div class="row">
          <!-- Image Slider on Left Side -->
          <div class="col-md-6">
            <div id="productCarousel" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img :src="thumbnails[0]" class="d-block w-100 img-fluid" alt="Product Image 1" />
                </div>
                <div class="carousel-item">
                  <img :src="thumbnails[1]" class="d-block w-100 img-fluid" alt="Product Image 2" />
                </div>
                <div class="carousel-item">
                  <img :src="thumbnails[2]" class="d-block w-100 img-fluid" alt="Product Image 3" />
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#productCarousel"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#productCarousel"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          <!-- Product Information on Right Side -->
          <div class="col-md-6">
            <p class="text-light"><strong>Herbal Essence</strong></p>

            <!-- Detailed Description -->
            <p>
              PINDA has been used as an ancient medicinal oil for thousands of years in the ancient HELA community. It
              is especially useful for addressing health issues such as rheumatoid arthritis, which is a chronic
              progressive disease causing inflammation in the joints, resulting in painful deformity and immobility,
              particularly in the fingers, wrists, feet, and ankles. This condition is often due to amavata (gastric
              acidic digestive juices in the stomach).
            </p>
            <p><strong>Benefits of PINDA Ancient Medicinal Oil:</strong></p>
            <ul>
              <li>Avoids swelling and stiffness</li>
              <li>Heals foot blisters and avoids burning sensation</li>
              <li>Excellent for treating numbness of the limbs</li>
              <li>
                Effective for chronic dermatitis; it is also an excellent application for beautifying and caring for the
                skin.
              </li>
            </ul>
            <p><strong>Usage:</strong> External use only.</p>

            <!-- Ingredients Section with Bullet Points -->
            <h4>Ingredients</h4>
            <ul>
              <li>Walmee (Glycyrrhiza glabra)</li>
              <li>Walmadata (Rubia cordifolia)</li>
              <li>Iramusu (Hemidesmus indicus)</li>
              <li>Sesame Oil (Sesamum indicum)</li>
              <li>And etc.</li>
            </ul>
          </div>
        </div>
      </div>
    </main>

    <LayoutFooter></LayoutFooter>

    <!-- Scroll Top -->
    <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center">
      <i class="bi bi-arrow-up-short"></i>
    </a>
  </div>
</template>

<script>
import LayoutHeader from "@/components/LayoutNav.vue";
import LayoutFooter from "@/components/LayoutFooter.vue";

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      thumbnails: [
        "assets/img/product/pinda/mockup-1.jpg",
        "assets/img/product/pinda/mockup-2.jpg",
        "assets/img/product/pinda/mockup-3.jpg",
      ],
      mobileNavActive: false,
    };
  },
  methods: {
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.closeMobileNav(); // Close the mobile nav after clicking a link
      }
    },
    toggleMobileNav() {
      this.mobileNavActive = !this.mobileNavActive;
      const body = document.querySelector("body");
      if (this.mobileNavActive) {
        body.classList.add("mobile-nav-active");
      } else {
        body.classList.remove("mobile-nav-active");
      }
    },
    closeMobileNav() {
      this.mobileNavActive = false;
      document.querySelector("body").classList.remove("mobile-nav-active");
    },
    isActiveSection(sectionId) {
      // Check if the current section is active by comparing window scroll position
      const section = document.getElementById(sectionId);
      if (section) {
        const top = section.offsetTop;
        const bottom = top + section.offsetHeight;
        const scrollY = window.scrollY + window.innerHeight / 2;
        return scrollY >= top && scrollY <= bottom;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.thumbnail {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.thumbnail.active {
  opacity: 0.6;
}
</style>
