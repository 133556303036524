<template lang="html">
  <div>
    <header id="header" class="header fixed-top">
      <!-- End Top Bar -->
      <LayoutHeader></LayoutHeader>
      <div class="branding d-flex align-items-cente">
        <div class="container position-relative d-flex align-items-center justify-content-between">
          <router-link to="/" class="logo d-flex align-items-center">
            <!-- Uncomment the line below if you also wish to use an image logo -->
            <img src="assets/img/logo/logo.png" style="width: 150px !important; height: 150px !important" alt="Logo" />
          </router-link>

          <nav id="navmenu" class="navmenu">
            <ul>
              <!-- Home -->
              <li>
                <a href="#hero" @click="scrollToSection('hero')" :class="{ active: isActiveSection('hero') }">Home</a>
              </li>

              <!-- About -->
              <li>
                <a href="#about" @click="scrollToSection('about')"
                  :class="{ active: isActiveSection('about') }">About</a>
              </li>

              <!-- Services -->
              <li>
                <a href="#services" @click="scrollToSection('services')"
                  :class="{ active: isActiveSection('services') }">Services</a>
              </li>

              <!-- Products - Uses router-link for SPA navigation -->
              <li>
                <router-link to="/product-page" @click="closeMobileNav">Products<br /></router-link>
              </li>

              <!-- Contact -->
              <li>
                <a href="#contact" @click="scrollToSection('contact')"
                  :class="{ active: isActiveSection('contact') }">Contact</a>
              </li>
            </ul>
            <!-- Mobile Nav Toggle Button -->
            <i class="mobile-nav-toggle d-xl-none bi" :class="mobileNavActive ? 'bi-x' : 'bi-list'"
              @click="toggleMobileNav"></i>
          </nav>
        </div>
      </div>
    </header>

    <main class="main">
      <!-- Hero Section -->
      <section id="hero" class="hero section">
        <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-5 justify-content-between">
            <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2><span>Welcome to </span><span class="accent">Helathel</span></h2>
              <p>Holistic Health Starts Here Discover the Power of Helathel.</p>
              <div class="d-flex">
                <a href="#about" class="btn-get-started">Who We Are</a>
                <a href="" class="glightbox btn-watch-video d-flex align-items-center" target="_blank"><i
                    class="bi bi-play-circle"></i><span>Watch Video</span></a>
              </div>
            </div>
            <div class="col-lg-5 order-1 order-lg-2">
              <img src="assets/img/hero-img.svg" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div class="icon-boxes position-relative" data-aos="fade-up" data-aos-delay="200">
          <div class="container position-relative">
            <div class="row gy-4 mt-5">
              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fas fa-leaf fa-1x"></i></div>
                  <h4 class="title">
                    <a href="" class="stretched-link">Natural</a>
                  </h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon"><i class="fas fa-vials fa-1x"></i></div>
                  <h4 class="title">
                    <a href="" class="stretched-link">Chemical Less</a>
                  </h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon">
                    <i class="fas fa-shield-alt fa-1x"></i>
                  </div>
                  <h4 class="title">
                    <a href="" class="stretched-link">Safely Made</a>
                  </h4>
                </div>
              </div>
              <!--End Icon Box -->

              <div class="col-xl-3 col-md-6">
                <div class="icon-box p-3 rounded">
                  <div class="icon">
                    <i class="fa-solid fa-bottle-droplet fa-1x"></i>
                  </div>
                  <h4 class="title">
                    <a href="" class="stretched-link">Helathel</a>
                  </h4>
                </div>
              </div>
              <!--End Icon Box -->
            </div>
          </div>
        </div>
      </section>
      <!-- /Hero Section -->

      <!-- About Section -->
      <section id="about" class="about section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Who We Are<br /></h2>
          <p>Experience Pure Nature with Helathel</p>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-center">Our Vision</h3>
              <img src="assets/img/about/vision-image.jpg" class="img-fluid rounded-4 mb-4" alt="" />
              <p class="text-center">
                At <b>HELATHEL</b> Online, we envision a future where our online marketplace is the go-to destination
                for consumers seeking high-quality products at reasonable prices. We strive to create a seamless and
                trustworthy shopping experience that not only drives profits for our parent company but also adds
                significant value to our brand constituents.
              </p>
              <p class="text-center">
                Our vision is to foster long-term customer loyalty through continuous innovation and a steadfast
                commitment to quality, ultimately establishing <b>HELATHEL</b> Online as a leader in the e-commerce
                industry.
              </p>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">
              <h3 class="text-center">Our Mission</h3>

              <p class="text-center">
                At <b>HELATHEL</b> Online, our mission is to provide a reliable and accessible online marketplace that
                offers high-quality products at reasonable prices. We are committed to driving profits for our parent
                company while simultaneously adding significant value to our brand constituents. Our goal is to create a
                seamless shopping experience that meets the diverse needs of our customers, fosters trust, and promotes
                long-term loyalty. By prioritizing customer satisfaction and maintaining a strong focus on quality, we
                aim to build a reputable brand that stands out in the marketplace.
              </p>
              <p class="text-center">
                We believe in the power of innovation and continuous improvement to enhance our offerings and deliver
                exceptional value to our customers.
              </p>
              <img src="assets/img/about/mission-image.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            </div>
          </div>
        </div>
      </section>
      <!-- /About Section -->
      <!-- Services Section -->
      <section id="services" class="services section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Services</h2>
        </div>
        <!-- End Section Title -->

        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-hand-holding-seedling"></i>
                </div>
                <h3>Herbal Medicine Cultivation</h3>
                <p>
                  Focus on the cultivation of herbal medicinal plants, processing, and manufacturing of herbal
                  medicines, along with the export and local trade of Ayurvedic herbal products.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-hands-holding-child"></i>
                </div>
                <h3>Treatment Centers</h3>
                <p>
                  Establish the Ayurvedic medical treatment and meditation centers, featuring environmentally friendly
                  mind therapy clay centers designed for both locals and foreign visitors.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-truck-plane"></i>
                </div>
                <h3>Products Distribution</h3>
                <p>
                  Engage in the business of general merchandise, acting as exporters, agents, and distributors of
                  locally manufactured Ayurvedic medical products.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-book-copy"></i>
                </div>
                <h3>Research HELA Medicine</h3>
                <p>
                  Conduct research and provide consultations on human healthcare, focusing on traditional Hela
                  medication, its historical and archaeological significance, and Ayurveda.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-solid fa-spa"></i>
                </div>
                <h3>Mind Health Centers</h3>
                <p>
                  Establishing Mind concentrative health centers of traditional indigenous martial arts.
                </p>
              </div>
            </div>
            <!-- End Service Item -->

            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
              <div class="service-item position-relative">
                <div class="icon">
                  <i class="fa-duotone fa-solid fa-handshake"></i>
                </div>
                <h3>Mind Consulting</h3>
                <p>
                  Ayurvedic Health Consulting offers personalized wellness guidance using natural remedies and holistic
                  practices to promote balance and well-being.
                </p>
              </div>
            </div>
            <!-- End Service Item -->
          </div>
        </div>
      </section>
      <!-- /Services Section -->
      <!-- Team Section -->
      <section id="product" class="product section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Products</h2>
          <p>Experience Pure Nature with Helathel</p>
        </div>
        <div class="container">
          <div class="row g-4 py-5">
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img src="assets/img/product/shula/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/shula/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/shula/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                  </div>
                </div>
                <div class="product-info">

                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-shula-pv" style="color: inherit; text-decoration: none">
                      The Great SHULA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img src="assets/img/product/sharsha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/sharsha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/sharsha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                  </div>
                </div>
                <div class="product-info">

                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-sharsha-pv" style="color: inherit; text-decoration: none">
                      The Great SHARSHA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img src="assets/img/product/masha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/masha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/masha/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                  </div>
                </div>
                <div class="product-info">

                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-masha-pv" style="color: inherit; text-decoration: none">
                      The Great MASHA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img src="assets/img/product/narana/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/narana/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                    <div class="hover-view">
                      <img src="assets/img/product/narana/mockup-1.jpg" alt="logo" class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'" />
                    </div>
                  </div>
                </div>
                <div class="product-info">

                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-narana-pv" style="color: inherit; text-decoration: none">
                      The Great NARANA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <router-link to="/product-page">
            <input type="submit" class="btn btn-dark" value="More Products" />
          </router-link>
        </div>
      </section>

      <!-- /Team Section -->
      <!-- Faq Section -->

      <!-- /Faq Section -->

      <!-- Recent Posts Section -->

      <!-- /Recent Posts Section -->

      <!-- Contact Section -->
      <section id="contact" class="contact section">
        <!-- Section Title -->
        <div class="container section-title" data-aos="fade-up">
          <h2>Contact</h2>
        </div>
        <!-- End Section Title -->

        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row gx-lg-0 gy-4">
            <div class="col-lg-4">
              <div class="info-container d-flex flex-column align-items-center justify-content-center">
                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Address</h3>
                    <p>Amila Rajakaruna Mudiyanse Pvt, No. 573, 10 Acre state, Mukalana, Samarapoththa, Pannala, Sri
                      Lanka ZIP - 60160</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>+94 765903122</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>contact@helathel.com</p>
                  </div>
                </div>
                <!-- End Info Item -->

                <div class="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                  <i class="bi bi-clock flex-shrink-0"></i>
                  <div>
                    <h3>Open Hours</h3>
                    <p>Mon-Sat: 8.00 AM - 6.00 PM</p>
                  </div>
                </div>
                <!-- End Info Item -->
              </div>
            </div>

            <div class="col-lg-8">
              <form action="forms/contact.php" method="post" class="php-email-form" data-aos="fade"
                data-aos-delay="100">
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                  </div>

                  <div class="col-md-6">
                    <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                  </div>

                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                  </div>

                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="8" placeholder="Message" required=""></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- End Contact Form -->
          </div>
        </div>
      </section>
      <!-- /Contact Section -->
      <!-- Product Section - Start -->
    </main>
    <LayoutFooter></LayoutFooter>
    <!-- Scroll Top -->
    <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
  </div>
</template>
<script>
import LayoutHeader from "@/components/LayoutNav.vue";
import LayoutFooter from "@/components/LayoutFooter.vue";

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      mobileNavActive: false,
    };
  },
  methods: {
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.closeMobileNav(); // Close the mobile nav after clicking a link
      }
    },
    toggleMobileNav() {
      this.mobileNavActive = !this.mobileNavActive;
      const body = document.querySelector("body");
      if (this.mobileNavActive) {
        body.classList.add("mobile-nav-active");
      } else {
        body.classList.remove("mobile-nav-active");
      }
    },
    closeMobileNav() {
      this.mobileNavActive = false;
      document.querySelector("body").classList.remove("mobile-nav-active");
    },
    isActiveSection(sectionId) {
      // Check if the current section is active by comparing window scroll position
      const section = document.getElementById(sectionId);
      if (section) {
        const top = section.offsetTop;
        const bottom = top + section.offsetHeight;
        const scrollY = window.scrollY + window.innerHeight / 2;
        return scrollY >= top && scrollY <= bottom;
      }
      return false;
    },
  },
};
</script>
