import { createRouter, createWebHashHistory } from 'vue-router'

import HomePage from '@/page/Home-Page.vue'
import ProductPage from '@/page/Product-Page.vue'
import GreatShulaPv from '@/product-page/Great-shula-pv.vue'
import GreatSharshaPv from '@/product-page/Great-Sharsha-pv.vue'
import GreatSavishaPv from '@/product-page/Great-Savisha-pv.vue'
import GreatPindaPv from '@/product-page/Great-Pinda-pv.vue'
import GreatNeelaPv from '@/product-page/Great-Neela-pv.vue'
import GreatNaranaPv from '@/product-page/Great-Narana-pv.vue'
import GreatMashaPv from '@/product-page/Great-Masha-pv.vue'
import GreatKubjaPv from '@/product-page/Great-Kubja-pv.vue'
import GreatKolasaPv from '@/product-page/Great-Kolasa-pv.vue'
import GreatChandraPv from '@/product-page/Great-Chandra-pv.vue'

const routes = [
    // ? home page show
    { path: '/', name: '', component: HomePage },
    { path: '/hero', name: 'hero', component: HomePage },
    {
        path: '/about',
        name: 'about',
        component: HomePage
    },
    {
        path: '/services',
        name: 'services',
        component: HomePage
    },
    {
        path: '/product',
        name: 'product',
        component: HomePage
    },
    {
        path: '/contact',
        name: 'contact',
        component: HomePage
    },
    { path: '/product-page', name: 'product-page', component: ProductPage },
    { path: '/great-shula-pv', name: 'great-shula-pv', component: GreatShulaPv },
    { path: '/great-sharsha-pv', name: 'great-sharsha-pv', component: GreatSharshaPv },
    { path: '/great-savisha-pv', name: 'great-savisha-pv', component: GreatSavishaPv },
    { path: '/great-pinda-pv', name: 'great-pinda-pv', component: GreatPindaPv },
    { path: '/great-neela-pv', name: 'great-neela-pv', component: GreatNeelaPv },
    { path: '/great-narana-pv', name: 'great-narana-pv', component: GreatNaranaPv },
    { path: '/great-masha-pv', name: 'great-masha-pv', component: GreatMashaPv },
    { path: '/great-kubja-pv', name: 'great-kubja-pv', component: GreatKubjaPv },
    { path: '/great-kolasa-pv', name: 'great-kolasa-pv', component: GreatKolasaPv },
    { path: '/great-chandra-pv', name: 'great-chandra-pv', component: GreatChandraPv },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // return to the saved scroll position if available (used for back/forward navigation)
            return savedPosition
        } else {
            // always scroll to top for new page loads
            return { top: 0 }
        }
    },
})

export default router
