<template lang="">
  <div>
    <header id="header" class="header fixed-top">
      <LayoutHeader></LayoutHeader>
      <!-- End Top Bar -->

      <div class="branding d-flex align-items-cente">
        <div class="container position-relative d-flex align-items-center justify-content-between">
          <router-link to="/" class="logo d-flex align-items-center">
            <!-- Uncomment the line below if you also wish to use an image logo -->
            <img src="assets/img/logo/logo.png" style="width: 150px !important; height: 150px !important" alt="Logo" />
          </router-link>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <!-- Just use @click without .native in Vue 3 -->
                <router-link to="/" class="active" @click="closeMobileNav">Home<br /></router-link>
              </li>
              <!-- Add more navigation items as needed -->
            </ul>

            <!-- Mobile nav toggle button -->
            <i
              class="mobile-nav-toggle d-xl-none bi"
              :class="mobileNavActive ? 'bi-x' : 'bi-list'"
              @click="toggleMobileNav"
            ></i>
          </nav>
        </div>
      </div>
    </header>
    <main class="main">
      <section id="product" class="product section">
        <div class="container">
          <div class="row g-4 py-5">
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/shula/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/shula/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/shula/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-shula-pv" style="color: inherit; text-decoration: none">
                      The Great SHULA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/sharsha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/sharsha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/sharsha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-sharsha-pv" style="color: inherit; text-decoration: none">
                      The Great SHARSHA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/masha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/masha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/masha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-masha-pv" style="color: inherit; text-decoration: none">
                      The Great MASHA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/narana/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/narana/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/narana/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-narana-pv" style="color: inherit; text-decoration: none">
                      The Great NARANA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/savisha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/savisha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/savisha/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-savisha-pv" style="color: inherit; text-decoration: none">
                      The Great SAVISHA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/pinda/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/pinda/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/pinda/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                 
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-pinda-pv" style="color: inherit; text-decoration: none">
                      The Great PINDA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/neela/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/neela/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/neela/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                 
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-neela-pv" style="color: inherit; text-decoration: none">
                      The Great NEELA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/kubja/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/kubja/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/kubja/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-kubja-pv" style="color: inherit; text-decoration: none">
                      The Great KUBJA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/kolasa/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/kolasa/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/kolasa/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-kolasa-pv" style="color: inherit; text-decoration: none">
                      The Great KOLASA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="product-single-card">
                <div class="product-top-area">
                  <div class="product-img">
                    <div class="first-view">
                      <img
                        src="assets/img/product/chandra/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/chandra/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                    <div class="hover-view">
                      <img
                        src="assets/img/product/chandra/mockup-1.jpg"
                        alt="logo"
                        class="img-fluid"
                        onerror="this.src='https://i.ibb.co/qpB9ZCZ/placeholder.png'"
                      />
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  
                  <h6 class="product-title text-truncate text-light">
                    <router-link to="/great-chandra-pv" style="color: inherit; text-decoration: none">
                      The Great CHANDRA
                    </router-link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <LayoutFooter></LayoutFooter>

    <!-- Scroll Top -->
    <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </div>
</template>
<script>
import LayoutHeader from "@/components/LayoutNav.vue";
import LayoutFooter from "@/components/LayoutFooter.vue";

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },
  data() {
    return {
      mobileNavActive: false,
    };
  },
  methods: {
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.closeMobileNav(); // Close the mobile nav after clicking a link
      }
    },
    toggleMobileNav() {
      this.mobileNavActive = !this.mobileNavActive;
      const body = document.querySelector("body");
      if (this.mobileNavActive) {
        body.classList.add("mobile-nav-active");
      } else {
        body.classList.remove("mobile-nav-active");
      }
    },
    closeMobileNav() {
      this.mobileNavActive = false;
      document.querySelector("body").classList.remove("mobile-nav-active");
    },
    isActiveSection(sectionId) {
      // Check if the current section is active by comparing window scroll position
      const section = document.getElementById(sectionId);
      if (section) {
        const top = section.offsetTop;
        const bottom = top + section.offsetHeight;
        const scrollY = window.scrollY + window.innerHeight / 2;
        return scrollY >= top && scrollY <= bottom;
      }
      return false;
    },
  },
};
</script>
